import React, { useEffect } from "react";
import * as _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ReactExport from "react-export-excel-fixed-xlsx";
import { directorActions } from "actions/directorActions";

const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function FooterContestar(props) {
  const dispatch = useDispatch();

  const locale = useSelector((state) => state.setting.locale);
  var preguntasDown = useSelector(
    (state) => state.directorReducer.preguntasDown
  );

  var apiDONE = useSelector((state) => state.loading.APIactions);

  var mExcelSheets = [];
  if (preguntasDown && preguntasDown.length > 0) {
    var mSalida = _.groupBy(preguntasDown, "num");
    var data = [];
    Object.keys(mSalida).map((key) => {
      data.push({ id: key, pregunta: mSalida[key][0].titulo, tipo: mSalida[key][0].tipo });
    });
    mExcelSheets.push(
      <ExcelSheet data={data} name="Preguntas">
        <ExcelColumn label="Id" value="id" />
        <ExcelColumn label="Pregunta" value="pregunta" />
        <ExcelColumn label="Tipo" value="tipo" />
      </ExcelSheet>
    );
    Object.keys(mSalida).map((key) => {
      var mData = [];
      mSalida[key].map((val) => {
        mData.push({respuesta:val.respuesta });
      });

      mExcelSheets.push(
        <ExcelSheet data={mData} name={mSalida[key][0].titulo}>
          <ExcelColumn label="Respuesta" value="respuesta" />
        </ExcelSheet>
      );

    });
  }
  useEffect(() => {

    dispatch(directorActions.getPreguntasDownload(props.clave));
  }, [apiDONE]);

 //aqui esta el boton de sieguiente
  return (
    <footer className="footerbgContestar ">
      <Grid container>
        <Grid item xs={3}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}
export default FooterContestar;
