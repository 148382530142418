import actionTypes from "constants/actionTypes";

export const municipalitiesActions = {
  getMunicipalities,
  getPostalCodes
};

function getMunicipalities() {
  return {
    type: actionTypes.GET_MUNICIPALITIES
  };
}

function getPostalCodes() {
  return {
    type: actionTypes.GET_POSTALCODES
  };
}
