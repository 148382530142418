import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Language } from "components/Language";
import styled from "styled-components";
import logoXcelenceNew from "assets/img/xcelence.svg";
import logoBertelsmann from "assets/img/logoBertelsmann.png";

const StyledUnproyectode = styled.span`
  color: var(--color-negro, black);
  font-size: 10px;
  font-family: Museo Sans;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 0.7px;
  word-wrap: break-word;
`;

const StyledLogoFundaction = styled.img`
  width: 147px;
  height: 40px;
  position: relative;
`;

const StyledFramePreFooter = styled.div`
  width: 263px;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
`;

const StyledImgLogo = styled.img`
  width: 156px;
  height: 40px;
  position: relative;
`;

const StyledPreFooter = styled.div`
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: var(--color-blanco, white);
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  margin-bottom: 24px;

  @media (max-width: 576px) {
    flex-direction: column;
    /*align-items: start;*/
    align-items: center;
    gap: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const StyledHrefBase = styled.span`
  color: var(--color-blanco, white);
  font-size: 14px;
  font-family: MuseoSans;
  font-weight: 500;
  line-height: 17.5px;
  letter-spacing: 0.42px;
  word-wrap: break-word;

  &:hover {
    color: var(--color-hover, #ddd);
  }
`;

const StyledItemA = styled(StyledHrefBase).attrs({ as: "a" })`
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const StyledPoltica = styled(StyledHrefBase).attrs({ as: Link })`
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 0.7px;
  font-size: 10px;
`;

const StyledFrame10 = styled.div`
  width: 88px;
  height: 34px;
  position: relative;
`;

const StyledFrame = styled.div`
  width: 100%;
  max-width: 320px;
  width: 320px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;

  @media (max-width: 1200px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 576px) {
  }
`;

const StyledFrameContent = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  display: flex;
  width: 100%;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const StyledContentFooter = styled.div`
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 48px;
  padding-bottom: 52px;
  background: var(--color-grana, #ca1141);
  border-radius: 12px;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
`;

const StyledFooter = styled.footer`
  padding: 0 80px 120px;
  background-color: var(--silver-color);

  @media (max-width: 1200px) {
    padding: 0 50px 100px;
  }
  @media (max-width: 768px) {
    padding: 0 40px 80px;
  }
  @media (max-width: 576px) {
    padding: 0 16px 40px;
  }
`;

const useStyles = makeStyles({
  footerContainer: {
    backgroundColor: "var(--footer-color)",
    padding: 15,
    "& a:hover": {
      text: "#fff",
      textDecoration: "underline",
    },
    "& a, & span": {
      color: "#fff",
    },
  },
});

function FooterNew() {
  const classes = useStyles();

  return (
    <StyledFooter>
      <StyledPreFooter>
        <Link to={{ pathname: "/user/login" }}>
          <StyledImgLogo src={logoXcelenceNew} />
        </Link>

        <StyledFramePreFooter>
          <StyledUnproyectode>Un proyecto de:</StyledUnproyectode>
          <a href="https://www.fundacionbertelsmann.org/">
            <StyledLogoFundaction src={logoBertelsmann} />
          </a>
        </StyledFramePreFooter>
      </StyledPreFooter>

      <StyledContentFooter>
        <StyledFrameContent>
          <StyledFrame>
            <StyledItemA href="https://xcelence.es/propuesta/">
              <Language langKey="footer.proposal" />
            </StyledItemA>
            <StyledItemA href="https://xcelence.es/diez-claves/">
              <Language langKey="footer.tenkeys" />
            </StyledItemA>
            <StyledItemA href="https://xcelence.es/premios-orientacion/">
              <Language langKey="footer.equipment" />
            </StyledItemA>
            <StyledItemA href="https://xcelence.es/evaluacion/">
              <Language langKey="footer.xcelece" />
            </StyledItemA>
            <StyledItemA href="https://xcelence.es/informe/">
              <Language langKey="footer.report" />
            </StyledItemA>
            <StyledItemA href="https://xcelence.es/?page_id=460">
              <Language langKey="footer.award" />
            </StyledItemA>
            <StyledItemA href="https://xcelence.es/recursos/">
              <Language langKey="footer.measures" />
            </StyledItemA>
          </StyledFrame>
          <StyledFrame>
            <StyledPoltica to={{ pathname: "/aviso-legal/" }}>
              <Language langKey="term_conditions" />
            </StyledPoltica>
            <StyledPoltica to={{ pathname: "/politica-privacidad/" }}>
              <Language langKey="privicy_policies" />
            </StyledPoltica>
            <StyledPoltica to={{ pathname: "/politica-de-cookies/" }}>
              <Language langKey="policy_cookies" />
            </StyledPoltica>
            <a> </a>
            <a> </a>
            <StyledItemA href="https://www.xcelence.es/user/contact">
              <Language langKey="contact" />
            </StyledItemA>
          </StyledFrame>
        </StyledFrameContent>
        <StyledFrame10 />
      </StyledContentFooter>
    </StyledFooter>
  );
}

export default FooterNew;
