import React from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import { Language } from "components/Language.js";

function DeleteOk(props) {
  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item xs={12}>
            <div className="h1home">
              <Language langKey="deleted.success" />
            </div>
            <div className="txtHome marginSubTitle">
              <Language langKey="deleted.info" parse />
            </div>

            <div className="subh1home marginSubTitle">
              <Language langKey="deleted.hope_back" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <NavLink to="/user/login" className="btn btnHome">
              <Language langKey="signup.go_home" />
            </NavLink>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default DeleteOk;
