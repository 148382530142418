import React from "react";
import Grid from "@material-ui/core/Grid";
import { NavLink, Link } from "react-router-dom";
import { Language } from "components/Language";
import NavBarAuthNew from "views/NavBar/NavBarAuthNew.js";
import CardNew from "components/Card/CardNew.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const RegisterOkOtros = (props) => {
  return (
    <div className="fullPageNew">
      <NavBarAuthNew />

      <div className="defaultFull defaultFullNew">
        <CardNew className="bgBlur bgBlurBlue">
          <Grid container spacing={0} justifyContent="center">
            <Grid item xs={12}>
              <div className="h1homeNew">
                <Language langKey="signup.thanks" />
              </div>
              <div className="txtHomeNew">
                <Language parse langKey="signup.sucess_teacher" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <NavLink to="/user/login" className="btnNew btnNewPrimary">
                <Language langKey="signup.go_home" />
                <FontAwesomeIcon icon={faAngleRight} className="iconAngleRight" />
              </NavLink>
            </Grid>
          </Grid>
        </CardNew>
      </div>
    </div>
  );
};
export default RegisterOkOtros;
