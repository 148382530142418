/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useStyles } from '../privacy/PrivacyES'

export const CoockiesES = () => {
  const classes = useStyles();

  return (
    <div  className={classes.stacticHTML}>
      <h2 className={classes.title}>Política de Cookies</h2>
      <div className="textPrivacity marginSubSt">
        El presente sitio web www.xcelence.es (en adelante, el Sitio Web)
        utiliza cookies propias y de terceros para mejorar su experiencia en
        la misma.
        <br />
        <br />
        <br />
        <p className="titleBoldPrimary bold">1. ¿Qué son las cookies?</p>
        <br />
        <br />
        Una Cookie es un pequeño archivo que se almacena en el ordenador del
        usuario y nos permite reconocerle. El conjunto de cookies nos ayuda
        a mejorar la calidad de nuestro Sitio Web.
        <br />
        <br />
        Las cookies son esenciales para el funcionamiento de internet,
        aportando innumerables ventajas en la prestación de servicios
        interactivos, facilitándole la navegación y usabilidad de nuestro
        Sitio Web. Tenga en cuenta que las cookies no pueden dañar su equipo
        y que, a cambio, el que estén activadas nos ayudan a identificar y
        resolver los errores.
        <br />
        <br />
        <p className="titleBoldPrimary bold">
          2. ¿Qué tipos de cookies utiliza nuestro Sitio Web?
        </p>{" "}
        <br />
        <br />
        Según la entidad que las gestiona:
        <ul>
          <li>
            Cookies propias: son aquéllas que se envían al equipo terminal
            del usuario desde un equipo o dominio gestionado por el propio
            editor y desde el que se presta el servicio solicitado por el
            usuario.
          </li>
          <li>
            Cookies de terceros: son aquéllas que se envían al equipo
            terminal del usuario desde un equipo o dominio que no es
            gestionado por el editor, sino por otra entidad que trata los
            datos obtenidos a través de las cookies. En el caso que las
            cookies sean instaladas desde un equipo o dominio gestionado por
            el propio editor pero la información que se recoja mediante
            éstas sea gestionada por un tercero, no pueden ser consideradas
            como cookies propias.
          </li>
        </ul>
        Según el plazo de tiempo que permanecen activas:
        <ul>
          <li>
            Cookies de sesión: son cookies temporales que permanecen en el
            archivo de cookies de su navegador hasta que abandone el Sitio
            Web, por lo que ninguna queda registrada en el disco duro del
            usuario. La información obtenida por medio de estas cookies,
            sirven para analizar pautas de tráfico en el Sitio Web. A la
            larga, esto nos permite proporcionar una mejor experiencia para
            mejorar el contenido facilitando su uso.
          </li>
          <li>
            Cookies permanentes: son almacenadas en el disco duro y nuestro
            Sitio Web las lee cada vez que usted realiza una nueva visita.
            Un Sitio Web permanente posee una fecha de expiración
            determinada. La cookie dejará de funcionar después de esa fecha.
          </li>
        </ul>
        Según el propósito:
        <ul>
          <li>
            Cookies técnicas: Son aquéllas necesarias para la navegación y
            el buen funcionamiento de nuestro Sitio Web. Permiten, por
            ejemplo, controlar el tráfico y la comunicación de datos,
            acceder a partes de acceso restringido, utilizar elementos de
            seguridad, almacenar contenidos para poder difundir vídeos o
            compartir contenidos a través de redes sociales.
          </li>
          <li>
            Cookies de análisis: Son aquéllas que bien tratadas por nosotros
            o por terceros, nos permiten cuantificar el número de usuarios y
            realizar la medición y análisis estadístico de la utilización
            que hacen los usuarios de nuestro Sitio Web. Para ello
            analizamos la navegación en nuestro Sitio Web con el fin de
            mejorar la oferta de servicios que ofrecemos.
          </li>
        </ul>
        <br />
        <br />
        <p className="titleBoldPrimary bold">
          3. Relación y descripción de las cookies propias que utilizamos en
          el Sitio Web
        </p>{" "}
        <br />
        <br />
        Nombre de la cookie: xcauth
        <br />
        Descripción: Esta cookie informa si el usuario está autenticado o no
        <br />
        Servidor desde el que se envía: www.xcelence.es
        <br />
        Propia o de terceros: Propia
        <br />
        Finalidad: Necesaria
        <br />
        Fecha de caducidad: 90 días
        <br />
        Excluida (del deber de la información y consentimiento) <br /><br />

          Nombre de la cookie: xctoken
        <br />
        Descripción: Cookie que almacena el token de autenticación del usuario conectado
        <br />
        Servidor desde el que se envía: www.xcelence.es
        <br />
        Propia o de terceros: Propia
        <br />
        Finalidad: Necesaria
        <br />
        Fecha de caducidad: 90 días
        <br />
        Excluida (del deber de la información y consentimiento) <br /><br />

          Nombre de la cookie: xcrefreshToken
        <br />
        Descripción: Cookie que almacena el token para actualizar la autenticación del usuario conectado
        <br />
        Servidor desde el que se envía: www.xcelence.es
        <br />
        Propia o de terceros: Propia
        <br />
        Finalidad: Necesaria
        <br />
        Fecha de caducidad: 90 días
        <br />
        Excluida (del deber de la información y consentimiento) <br /><br />

            Nombre de la cookie: xcuser
        <br />
        Descripción: Cookie que almacena los datos del usuario
        <br />
        Servidor desde el que se envía: www.xcelence.es
        <br />
        Propia o de terceros: Propia
        <br />
        Finalidad: Necesaria
        <br />
        Fecha de caducidad: 90 días
        <br />
        Excluida (del deber de la información y consentimiento) <br /><br /><br />
        <br />
        <p className="titleBoldPrimary bold">
          4. Relación de terceros prestadores de servicios de cookies en el
          Sitio Web
        </p>{" "}
        <br />
        <br /> Nombre del proveedor: Google Inc.
        <br />
        Descripción: Cookies de Google Analytics. Generan un ID de usuario
        anónimo, que es el que se utiliza para hacer recuento de cuantas
        veces visita el sitio un usuario. También registra cuando fue la
        primera y la última vez que visitó la web. Asimismo, calcula cuando
        se ha terminado una sesión, origen del usuario, y keywords.
        (Cookies:_ga, _gat).
        <br />
        Finalidad: Analíticas
        <br />
        Si desea más información del uso de las cookies de terceros:
        https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
        *Esta lista se actualizará con la mayor celeridad posible a medida
        que cambien los servicios del Sitio Web ofrecidos en el mismo. Sin
        embargo, ocasionalmente durante esta actualización puede ser que la
        lista no incluya ya una cookie, aunque siempre se referirá a cookies
        con propósitos idénticos a los registrados en esta lista.
        <br /><br />
        <p className="titleBoldPrimary bold">5. Garantías complementarias - Gestión de cookies</p>
        <br />
        <br />
        Como garantía complementaria a las anteriormente descritas, el
        registro de las cookies podrá estar sujeto a su aceptación durante
        la instalación o puesta al día del navegador usado, y esta
        aceptación puede en todo momento ser revocada mediante las opciones
        de configuración de contenidos y privacidad disponibles. <br />
        <br />
        Muchos navegadores permiten activar un modo privado mediante el cual
        las cookies se borran siempre después de su visita. Dependiendo de
        cada navegador este modo privado, puede tener diferentes nombres. A
        continuación encontrará una lista de los navegadores más comunes y
        los diferentes nombres de este &quot;modo privado&quot;.
        <br />
        <br />
        <p className="titleBoldPrimary bold">6. Consentimiento</p> <br />
        <br />
        Al acceder a nuestro Sitio Web, aparece una ventana emergente relativa a las
        Cookies, describiendo brevemente qué cookies se utilizan en el Sitio Web. En dicha
        ventana puede seleccionar “Aceptar”, otorgando su consentimiento para el uso de las
        cookies antes enunciadas en las condiciones contenidas en la presente Política de
        Cookies.
        <br />
        <br />
        A partir de la opción que tome acerca del uso de cookies en este
        Sitio Web se la enviará una cookie adicional para salvaguardar su
        elección.
        <br />
        <br />
        Para utilizar este Sitio Web no resulta necesaria la instalación de
        cookies. El usuario puede no aceptarlas configurando su navegador
        para bloquearlas y, en su caso, eliminarlas.
        <br />
        <br />
        <p className="titleBoldPrimary bold">
          7. ¿Cómo permitir, bloquear o eliminar cookies?
        </p>{" "}
        <br />
        <br />
        De acuerdo con lo establecido por el Real Decreto ley 13/2012 le
        informamos de que puede permitir, bloquear o eliminar las cookies
        instaladas en su equipo mediante la configuración de las opciones de
        su navegador de internet. En el caso de que las bloquee, es posible
        que ciertos servicios que necesitan su uso no estén disponibles para
        usted.
        <br />
        <br />A continuación, le ofrecemos enlaces en los que encontrará
        información sobre cómo puede activar sus preferencias en los
        principales navegadores:
        <br />
        <br />
        <a
          href="https://support.google.com/chrome/answer/95647?hl=es"
          target="_blank"
          className="maindarkLink"
        >
          Google Chrome
        </a>
        <br />
        <a
          href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectlocale=en-US&redirectslug=Cookies"
          target="_blank"
          className="maindarkLink"
        >
          Mozilla Firefox
        </a>
        <br />
        <a
          href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11"
          target="_blank"
          className="maindarkLink"
        >
          Internet Explorer
        </a>
        <br />
        <a
          href="http://www.apple.com/legal/privacy/es/cookies/"
          target="_blank"
          className="maindarkLink"
        >
          Safari
        </a>
        <br />
        <a
          href="https://support.apple.com/es-es/HT201265"
          target="_blank"
          className="maindarkLink"
        >
          Safari para IOS (iPhone, iPad)
        </a>
        <br />
        <a
          href="http://www.macromedia.com/support/documentation/es/flashplayer/help/settings_manager07.html"
          target="_blank"
          className="maindarkLink"
        >
          Cookies Flash
        </a>
        <br />
        <a
          href="http://help.opera.com/Windows/12.00/es-ES/cookies.html"
          target="_blank"
          className="maindarkLink"
        >
          Opera
        </a>
        <br />
        <a
          href="https://support.google.com/chrome/answer/2392971?hl=es"
          target="_blank"
          className="maindarkLink"
        >
          Chrome para Android
        </a>
        <br />
        <br />
        Finalmente, puede usted dirigirse al portal{" "}
        <a
          href="http://www.youronlinechoices.eu/"
          target="_blank"
          className="maindarkLink"
        >
          Your Online Choices
        </a>{" "}
        dónde además de encontrar información útil, podrá configurar,
        proveedor por proveedor, sus preferencias sobre las cookies
        publicitarias de terceros.
        <br />
        <br />
        <p className="titleBoldPrimary bold">8. Importante</p>
        <br />
        <br />
        Por favor, lea atentamente la sección de ayuda de su navegador para
        conocer más acerca de cómo activar el "modo privado". Podrá seguir
        visitando nuestro Sitio Web aunque su navegador esté en "modo
        privado", si bien, su navegación por nuestro Sitio Web puede no ser
        óptima y algunas utilidades pueden no funcionar correctamente.
        <br />
        <br />
        <p className="titleBoldPrimary bold">9. Contacta con nosotros</p>
        <br />
        <br />
        Para más información sobre el tratamiento de datos personales, consulte nuestra
        Política de Privacidad o contáctenos:
        <br />
        <br />
        Dirección: Travessera de Gràcia, 47-49, - 08021 Barcelona (España),
        <br />
        <br />
        Dirección de correo electrónico: info@fundaciónbertelsmann.org
        <br />
        <br />
        Teléfono: + 34 93 268 73 73
      </div>
    </div>
  );
}
