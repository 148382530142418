import {
  blackColor,
  whiteColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";

const cardStyle = {
  card: {
    border: "0",
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: whiteColor,
    width: "100%",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    "@media (max-width: 576px)": {
      marginBottom: "20px",
      marginTop: "20px",
    },
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none"
  },
  cardProfile: {
    marginTop: "30px",
    textAlign: "center"
  },
  cardChart: {
    "& p": {
      marginTop: "0px",
      paddingTop: "0px"
    }
  }
};
export const cardStyleNew = {
  card: {
    border: "0",
    color: "var(--dark-color)",
    background: whiteColor,
    width: "100%",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: 16,
    // fontWeight: 300,
    padding: "48px 64px",
    borderRadius: "12px",
    maxWidth: 544,
    marginBottom: 60,
    marginTop: 30,
    marginRight: "auto",
    marginLeft: "auto",
    // lineHeight: "1.5",

    "@media (max-width: 991px)": {
      padding: "32px 40px",
      marginBottom: 40,
    },
    "@media (max-width: 576px)": {
      padding: "24px 24px",
      marginBottom: 20,
      marginTop: 20,
    }
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none"
  },
  cardProfile: {
    marginTop: "30px",
    textAlign: "center"
  },
  cardChart: {
    "& p": {
      marginTop: "0px",
      paddingTop: "0px"
    }
  }
};

export default cardStyle;
