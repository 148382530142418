const Breakpoint = {
  base: 0, // to xs
  xxs: 320, // to xs
  xs: 360, // to sm
  sm: 576, // to md
  md: 768, // to lg
  lg: 992, // to xl
  xl: 1280, // to xxl
  xxl: 1440, // to max
};

/**
 * Genera una expresión CSS para un valor responsivo basado en los puntos de quiebre definidos.
 *
 * @param {number} minValue - Valor mínimo píxeles sin [px].
 * @param {number} maxValue - Valor máximo píxeles sin [px].
 * @param {number | "base" | "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl"} [minScreen=Breakpoint["xs"]] - Punto de quiebre mínimo, ya sea como número o clave de `Breakpoint`.
 * @param {number | "base" | "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl"} [maxScreen=Breakpoint["xl"]] - Punto de quiebre máximo, ya sea como número o clave de `Breakpoint`.
 * @returns {sResponsive} - Cadena con la expresión CSS para el tamaño responsivo.
 */
export const r = (maxValue, minValue, maxScreen = Breakpoint["xl"], minScreen = Breakpoint["xs"]) => {
  if (maxValue === minValue || !maxValue) return minValue + "px";

  const _minScreen = typeof minScreen === "string" ? Breakpoint[minScreen] : minScreen;
  const _maxScreen = typeof maxScreen === "string" ? Breakpoint[maxScreen] : maxScreen;

  return `calc( ${minValue}px + (${maxValue} - ${minValue}) * ((100vw - ${_minScreen}px) / (${_maxScreen} - ${_minScreen})))`;
};

export const fileToBase64 = async (file) => new Promise(resolve => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload =  () => resolve(reader.result);

  reader.onerror = (error) => {
     resolve('failed');

    console.log('Error: ', error);
  };
});
