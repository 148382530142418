/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/iconsStyle.js";

const useStyles = makeStyles(styles);

export function CheckedIconOk() {
  return (
    <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
    </svg>
  );
}
export default function CheckedIcon() {
  return (
    <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M15.5 5C15.5 5 19 5 19 8.5C19 12 19 12 19 12C19 12 19 12 19 15.5C19 19 15.5 19 15.5 19H12H8.5C8.5 19 5 19 5 15.5C5 12 5 8.5 5 8.5C5 8.5 5 5 8.5 5C12 5 12 5 12 5H15.5ZM15.5 3C12.5 3 11.5 3 8.5 3C5.5 3 3 5 3 8.5C3 12 3.0091 15.3376 3 15.5C3 18.5 5.5 21 8.5 21C8.5 21 12.5 21.0001 15.5 21C18.5 20.9999 21 18.4999 21 15.5C21 12.5001 21 11.0001 21 8.50005C21 6 18.5 3 15.5 3Z"></path>
    </svg>
  );
}
