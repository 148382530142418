import React from "react";
import NavBarAuthNew from "views/NavBar/NavBarAuthNew.js";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { Language } from "components/Language.js";
import { useSelector } from "react-redux";
import CardNew from "components/Card/CardNew";
import { ButtonNew } from "components/CustomButtons/Button";

function Register() {
  const history = useHistory();
  const locale = useSelector((state) => state.setting.locale);

  const director = () => {
    history.push("/user/register/director");
  };

  const investigador = () => {
    history.push("/user/register/investigador");
  };

  const otros = () => {
    history.push("/user/register/otros");
  };

  return (
    <div className="fullPageNew bgBlurNab">
      <NavBarAuthNew />
      <div className="defaultFull defaultFullNew defaultFullSpace">
        <Grid container justifyContent="center" className="defaultgridNew">
          <Grid item xs={12}>
            <div className="h1homeNew h1homeRegister">
              <Language langKey="signup.title_new" />
            </div>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                <CardNew>
                  <div className="signupBoxHeader">
                    <figure>
                      <img src={require(`assets/img/registro/icon-school.png`)} />
                    </figure>

                    <h3 className="h2New">
                      <Language langKey="signup.educationalCenterTitle" />
                    </h3>
                    <p>
                      <Language langKey="signup.educationalCenterIntro" />
                    </p>
                  </div>

                  <div className="signupBox">
                    <div className="signupStep">
                      <p className="h3New">
                        <Language langKey="signup.stepTitle" /> 1
                      </p>
                    </div>
                    <p className="h5New">
                      <Language parse langKey="signup.directorRegistration" />
                    </p>
                    <ButtonNew
                      onClick={(e) => director()}
                      ctype="outlinePrimary"
                      className="btnNewRegister"
                      langKey="signup.btnRegisterCenter"
                    />
                  </div>

                  <hr className="signupHr" />

                  <div className="signupBox">
                    <div className="signupStep">
                      <p className="h3New">
                        <Language langKey="signup.stepTitle" /> 2
                      </p>
                    </div>
                    <p className="h5New">
                      <Language parse langKey="signup.collaboratorRegistration" />
                    </p>
                    <ButtonNew
                      onClick={(e) => otros()}
                      ctype="outlinePrimary"
                      className="btnNewRegister"
                      langKey="signup.btnRegisterCollaborator"
                    />
                  </div>
                </CardNew>
              </Grid>

              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                <CardNew>
                  <div className="signupBoxHeader">
                    <figure>
                      <img src={require(`assets/img/registro/icon-lupa.png`)} />
                    </figure>

                    <h3 className="h2New">
                      <Language langKey="signup.researcherTitle" />
                    </h3>
                    <p className="pNew">
                      <Language langKey="signup.researcherIntro" />
                    </p>
                  </div>

                  <div className="signupBox">
                    <p className="h5New">
                      <Language langKey="signup.researcherRegistration" />
                    </p>
                    <ButtonNew
                      onClick={(e) => investigador()}
                      ctype="outlinePrimary"
                      className="btnNewRegister"
                      langKey="signup.btnSignUp"
                    />
                  </div>
                </CardNew>
              </Grid>
            </Grid>

            <div className="txtHomeNew marginSubText text-center"></div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Register;
