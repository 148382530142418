import actionTypes from "constants/actionTypes";
const initialState = {
  municipalities: null,
  postalcodes: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MUNICIPALITIES:
      return {
        ...state,
        municipalities: action.payload
      };
    case actionTypes.SET_POSTALCODES:
      return {
        ...state,
        postalcodes: action.payload
      };
    default:
      return state;
  }
};
