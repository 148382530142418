/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";
import masterData from "constants/masterData";
import { useHistory } from "react-router-dom";
import { Input } from "antd";
import { Language, getRawMessage } from "components/Language";

import ArrowAzul from "assets/css/ArrowAzul.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { municipalitiesActions } from "actions/municipalitiesActions.js";
import NavBarAuthNew from "views/NavBar/NavBarAuthNew.js";
import CardNew from "components/Card/CardNew.js";
import { ButtonNew } from "components/CustomButtons/Button.js";
import CheckedIcon, { CheckedIconOk } from "views/Icons/CheckedIcon";
import IconArrowDark from "components/icons/IconArrowDark";

const { TextArea } = Input;

function RegisterInvestigador() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [valid, setValid] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [postalCode, setPostalCode] = useState(0);
  const [municipality, setMunicipality] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [mailError, setMailError] = useState(false);

  const [mailExiste, setMailExiste] = useState(false);

  const [saveError, setSaveError] = useState(false);
  const [municipalitiesOption, setMunicipalitiesOption] = useState([]);
  const locale = useSelector(state => state.setting.locale);

  const [language, setLanguage] = useState(locale);

  const setChangeTerminos = event => {
    setTerminos(event.target.checked);
  };
  const setChangeNombre = event => {
    setNombre(event.target.value);
  };
  const setChangeApellidos = event => {
    setApellidos(event.target.value);
  };
  const setChangeEmail = event => {
    setEmail(event.target.value);
  };
  const setChangePostalCode = event => {
    setPostalCode(event.target.value);
  };

  const setChangeMunicipality = event => {
    setMunicipality(event?.id || null);
  };

  const setChangeMensaje = event => {
    setMensaje(event.target.value);
  };

  const /** @type {import("types/index.js").MunicipalityModel[]} */ dataMunicipalities = useSelector(
      state => state.municipalitiesReducer.municipalities
    );
  var emailvalid = useSelector(state => state.authReducer.emailvalid);
  var emailerror = useSelector(state => state.authReducer.emailerror);

  var altaInvestigadorSuccess = useSelector(
    state => state.authReducer.altaInvestigadorSuccess
  );
  var altaInvestigadorError = useSelector(
    state => state.authReducer.altaInvestigadorError
  );

  const noValidEmail = function(mEmail) {
    var noValid = false;
    var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!mEmail.match(EMAIL_REGEX)) noValid = true;

    return noValid;
  };

  const saveForm = () => {
    setSubmitted(true);

    if (email) {
      setMailError(noValidEmail(email));
      if (
        nombre !== "" &&
        nombre.length > 1 &&
        nombre.length < 40 &&
        mensaje !== "" &&
        mensaje.length > 2 &&
        mensaje.length < 40 &&
        apellidos !== "" &&
        apellidos.length > 1 &&
        apellidos.length < 60 &&
        !noValidEmail(email)
      ) {
        setValid(true);
      }
    } else {
      setValid(false);
      setMailError(true);
    }
  };

  useEffect(() => {
    dispatch(municipalitiesActions.getMunicipalities());
  }, []);

  useEffect(() => {
    if (dataMunicipalities?.length) {
      const data = dataMunicipalities.map(it => ({
        title: `${it.nombre}`,
        id: it.id_municipio
      }));
      setMunicipalitiesOption(data);
    }
  }, [dataMunicipalities]);

  useEffect(() => {
    if (valid) {
      dispatch(userActions.findEmail(email));
    }

    if (emailerror) {
      setMailError(true);
    }

    if (emailvalid === 0) {
      setMailError(false);
      setMailExiste(false);
      if (!sending) {
        dispatch(
          userActions.registerInvestigador({
            nombre,
            apellidos,
            email,
            perfil: masterData.PROFILE_INVESTIGADOR,
            cargo: 0,
            language,
            mensajealta: mensaje,
            postalCode: postalCode,
            municipality: municipality
          })
        );
        setSending(true);
      }
    }

    if (emailvalid === 1) {
      setMailError(true);
      setMailExiste(true);
      setValid(false);
    }

    if (altaInvestigadorSuccess) {
      if (altaInvestigadorSuccess.code === 200) {
        setSaveError(false);
        dispatch(userActions.endRegister());
        history.push("/user/register/okinvestigador");
      }
    }
    if (altaInvestigadorError) {
      setSaveError(true);
    }
  }, [
    valid,
    emailvalid,
    emailerror,
    altaInvestigadorSuccess,
    altaInvestigadorError,
    sending
  ]);

  return (
    <div className="fullPageNew">
      <NavBarAuthNew />
      <div className="defaultFull defaultFullNew">
        <CardNew className="bgBlur bgBlurGreen">
          <Grid container spacing={0} justifyContent="center">
            <Grid item xs={12}>
              <div className="h1homeNew">
                <Language langKey="signup.reseacher" />
              </div>
              <div className="subh1home subh1homeNew">
                <Language langKey="signup.i_am_reseacher" />.
              </div>

              <div className="txtHomeNew">
                <Language langKey="signup.reseacher_caption" />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.name" /> *
              </div>
              <TextField
                onChange={e => setChangeNombre(e)}
                id="nombre"
                name="nombre"
                defaultValue={nombre}
                error={submitted && !nombre}
                className="inputTransform"
                variant="outlined"
                required
                placeholder={getRawMessage("signup.name_caption")}
              />
              {submitted && !nombre && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_name" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.last_name" /> *
              </div>
              <TextField
                onChange={e => setChangeApellidos(e)}
                className="inputTransform"
                variant="outlined"
                defaultValue={apellidos}
                error={submitted && !apellidos}
                required
                id="apellidos"
                placeholder={getRawMessage("signup.last_name_caption")}
              />
              {submitted && !apellidos && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_last" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.email" /> *
              </div>
              <TextField
                onChange={e => setChangeEmail(e)}
                className="inputTransform"
                variant="outlined"
                error={submitted && mailError}
                defaultValue={email}
                required
                id="email"
                placeholder={getRawMessage("signup.email_caption")}
              />
              {submitted && mailError && (
                <div className="errorLayer">
                  {mailExiste ? (
                    <span>
                      <i className="fa fa-exclamation-triangle marginerror"></i>
                      <Language langKey="errors.exist_email" />
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-exclamation-triangle marginerror"></i>
                      <Language langKey="errors.center_email" />
                    </span>
                  )}
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.postal_code" /> *
              </div>
              <TextField
                onChange={e => setChangePostalCode(e)}
                className="inputTransform"
                variant="outlined"
                error={submitted && !postalCode}
                defaultValue={postalCode}
                required
                id="codigo_postal"
                placeholder={getRawMessage("signup.postal_code_caption")}
              />
              {submitted && !postalCode && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_cp" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.municipality" /> *
              </div>
              <FormControl variant="outlined" className="mt-3 w-100">
                <Autocomplete
                  id="municipality"
                  className="inputTransform inputTransformAutocomplete"
                  options={municipalitiesOption}
                  error={submitted && municipality}
                  getOptionLabel={option => option.title}
                  required
                  onChange={(_e, nv) => setChangeMunicipality(nv)}
                  popupIcon={<IconArrowDark />}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={getRawMessage("signup.municipality_caption")}
                    />
                  )}
                />
                {submitted && !municipality && (
                  <div className="errorLayer">
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey="errors.required_municipality" />
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="lang.label" />
              </div>
              <FormControl variant="outlined" className="mt-3 w-100">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  className="inputTransform"
                  IconComponent={IconArrowDark}
                  id="demo-simple-select-outlined"
                  value={language}
                  onChange={value => setLanguage(value.target.value)}
                >
                  <MenuItem value="ES">
                    <Language langKey="lang.es" />
                  </MenuItem>
                  <MenuItem value="CA">
                    <Language langKey="lang.ca" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.reason" /> *
              </div>
              <TextField
                multiline
                minRows={5}
                onChange={e => setChangeMensaje(e)}
                className="inputTransform"
                aria-label="minimum height"
                variant="outlined"
                value={mensaje}
                required
                id="mensaje"
                placeholder={getRawMessage("signup.reason_caption")}
              />
              {submitted && !mensaje && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: -11 }}></div>

              <FormControlLabel
                className="labelForm labelFormNew"
                error="true"
                control={
                  <Checkbox
                    checked={terminos}
                    onChange={setChangeTerminos}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    checkedIcon={<CheckedIconOk />}
                    icon={<CheckedIcon />}
                  />
                }
                label={<Language langKey="signup.terms" parse />}
              />

              {submitted && !terminos && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.terms" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <ButtonNew
                onClick={() => saveForm()}
                disabled={!!loading}
                className={`mb-32 ${loading ? "btnDisabledNew" : ""}`}
                langKey="send"
              />
              {loading && (
                <CircularProgress size={24} className="centerLoading" />
              )}

              <p className="obtext obtextNew">
                * <Language langKey="signup.confidential" />
              </p>

              {saveError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.internal" />
                </div>
              )}
            </Grid>
          </Grid>
        </CardNew>
      </div>
    </div>
  );
}

export default RegisterInvestigador;
