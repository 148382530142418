import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

export default function IconArrowDark(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="var(--dark-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M8 12L11.9929 15.9929C11.9968 15.9968 12.0032 15.9968 12.0071 15.9929L16 12"
      />
    </SvgIcon>
  );
}
