import React, { useState, useEffect } from "react";
import NavBarAuthNew from "views/NavBar/NavBarAuthNew.js";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";
import { Modal } from "react-bootstrap";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Language, getRawMessage } from "components/Language.js";
import { useForceUpdate } from "utils/custon-hooks.js";
import contentMedia from "assets/img/content-media.png";
import styled from "styled-components";
import CardNew from "components/Card/CardNew";
import { ButtonNew } from "components/CustomButtons/Button";
import { r } from "utils/global_helper";

// const StyledNavbar = styled(Navbar)`
const StyledBoxRight = styled.div`
  margin-top: 80px;
  max-width: 352px;
  margin-left: 8px;

  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: ${r(60, 30, "xl", "xs")};
    /* max-width: 544px; */
  }
  @media (max-width: 576px) {
    margin-left: 0;
    max-width: none;
  }
`;

function Login() {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [errorRepeat, setErrorRepeat] = useState(false);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState();
  const [repeat, setRepeat] = useState("");
  const [errorPWd, setErrorPwd] = useState(false);
  const [open, setOpen] = React.useState(false);
  const forceUpdate = useForceUpdate();

  const locale = useSelector((state) => state.setting.locale);
  const errorLogin = useSelector((state) => state.authReducer.error);
  const chgpwdSuccess = useSelector((state) => state.authReducer.firstpwdSuccess);
  const chgpwdError = useSelector((state) => state.authReducer.firstpwdError);
  const mLoading = useSelector((state) => state.loading.loading);

  useEffect(() => {
    forceUpdate();
  }, [locale]);

  useEffect(() => {
    if (errorLogin) {
      if (errorLogin.code === 499) {
        setError(false);
        setStep(2);
      } else {
        setErrorCode(errorLogin.code);
        setError(true);
      }
    } else {
      setError(false);
    }

    if (chgpwdSuccess) {
      setOpen(true);
    }
  }, [mLoading, errorLogin, chgpwdSuccess, chgpwdError]);

  const handleClose = () => {
    setOpen(false);
    setStep(1);
  };

  const setChangeRepeat = (event) => {
    setRepeat(event.target.value);
  };

  const setChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const setChangePwd = (event) => {
    setPwd(event.target.value);
  };

  const saveForm = () => {
    setError(false);
    if (username && pwd) dispatch(userActions.login(username, pwd));
  };

  const changePwd = () => {
    setErrorPwd(false);
    setErrorRepeat(false);
    if (pwd.length > 7) {
      if (pwd !== repeat) {
        setErrorRepeat(true);
      } else {
        setErrorRepeat(false);
        dispatch(userActions.firstpwd(username, pwd));
      }
    } else {
      setErrorPwd(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 13) saveForm();
  };

  return (
    <div className="fullPageNew">
      <NavBarAuthNew />

      <div className="defaultFull defaultFullNew">
        {step === 1 && (
          <Grid container className="defaultgridLogin" spacing={3} justifyContent="center" >
            <Grid item xs={12} md={7}>
              <CardNew className="bgBlur bgBlurOrange">
                <div className="h1homeNew">
                  <Language langKey="login_title" />
                </div>
                <div className="txtHomeNew">
                  <Language langKey="login_caption" />
                </div>

                <div className="formTextNew">
                  <Language langKey="user" /> *
                </div>

                <TextField
                  onKeyPress={(e) => handleKeyPress(e)}
                  onChange={(e) => setChangeUsername(e)}
                  id="username"
                  name="username"
                  error={error}
                  className="inputTransform"
                  variant="outlined"
                  required
                  placeholder={getRawMessage("user_placeholder")}
                />
                {error && (
                  <div className="errorLayer">
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey={"errors.login_" + errorCode} />
                  </div>
                )}

                <div className="formTextNew">
                  <Language langKey="pwd" /> *
                </div>
                <TextField
                  onKeyPress={(e) => handleKeyPress(e)}
                  onChange={(e) => setChangePwd(e)}
                  error={error}
                  id="pwd"
                  name="pwd"
                  className="inputTransform"
                  variant="outlined"
                  type="password"
                  required
                  placeholder={getRawMessage("pwd_placeholder")}
                />
                <div className="maindarkLinkContent">
                  <a className="maindarkLink primary-text" href="/user/forgot">
                    <Language langKey="forgot_pwd" />
                  </a>
                </div>

                <ButtonNew
                  onClick={(e) => saveForm()}
                  disabled={!!mLoading}
                  className={`${!mLoading ? "btnNewPrimary" : "btnDisabledTransform"}`}
                  langKey="login_submit"
                />

                <hr className="loginSpaceHr" />
                {mLoading && <CircularProgress size={24} className="centerLoading" />}

                <div>
                  <div className="h2home">
                    <Language langKey="no_registered" />
                  </div>
                  <NavLink
                    style={{ marginTop: "10px" }}
                    className="btnNew btnNewOutlinePrimary"
                    to="/user/register"
                  >
                    <Language langKey="create_account" /> {"  "}
                    <FontAwesomeIcon icon={faAngleRight} style={{marginLeft: 8, fontSize: 16}} />
                  </NavLink>
                </div>
              </CardNew>
            </Grid>

            <Grid item xs={12} md={5} style={{zIndex: 0}}>
              <StyledBoxRight>
                <h3 className="subtitleTransform dark-text ">
                  <Language langKey="logininfo.toolDescription" />
                </h3>
                <div className="textTransform">
                  <p className="primary-text font-600">
                    <Language langKey="logininfo.schoolDescription" />
                  </p>
                  <p>
                    <Language langKey="logininfo.schoolAction" />
                  </p>
                </div>

                <div className="textTransform">
                  <p className="primary-text font-600">
                    <Language langKey="logininfo.professionalDescription" />
                  </p>
                  <p>
                    <Language langKey="logininfo.professionalAction" />
                  </p>
                </div>

                <figure>
                  <img src={contentMedia} className="contentMedia" />
                </figure>
              </StyledBoxRight>
            </Grid>
          </Grid>
        )}
        {step === 2 && (
          <Grid container spacing={3} justifyContent="center" className="defaultgrid">
            <Modal show={open} onHide={handleClose}>
              <Modal.Header closeButton className="headerModal">
                <Modal.Title>
                  <Language langKey="change_pwd" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Language langKey="info_pwd_changed" />
              </Modal.Body>
              <Modal.Footer>
                <ButtonNew className={`btnCenter btnNewPrimary`} onClick={handleClose} langKey="login_title" />
              </Modal.Footer>
            </Modal>
            <Grid item xs={12}>
              <div className="h1homeNew">
                <Language langKey="change_pwd" />
              </div>
              <div className="txtHomeNew marginSubTitle">
                <Language langKey="login_first_access" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="user" /> *
              </div>

              <TextField
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setChangeUsername(e)}
                value={username}
                disabled
                id="username"
                name="username"
                error={error}
                className="inputTransform"
                variant="outlined"
                required
                placeholder={getRawMessage("user_placeholder")}
              />
              {error && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  {errorLogin.message}
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="pwd" /> *
              </div>

              <TextField
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setChangePwd(e)}
                error={error}
                id="pwd"
                name="pwd"
                className="inputTransform"
                variant="outlined"
                type="password"
                required
                placeholder={getRawMessage("pwd_placeholder")}
              />
              {errorPWd && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="min_pwd_error" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="repeat_pwd" /> *
              </div>
              <TextField
                onChange={(e) => setChangeRepeat(e)}
                className="inputTransform passwordField"
                defaultChecked={repeat}
                error={errorRepeat}
                type="password"
                variant="outlined"
                required
                id="passwordRepeat"
                placeholder={getRawMessage("repeat_pwd")}
              />
              {errorRepeat && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="pwd_not_match" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <ButtonNew
                onClick={(e) => changePwd()}
                variant="contained"
                disabled={!!mLoading}
                className={` ${!mLoading ? "btnNewPrimary" : "btnDisabledNew"} `}
                langKey="pwd_changed"
              />

              {mLoading && <CircularProgress size={24} className="centerLoading" />}
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}
export default Login;
