import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import styles from "assets/jss/material-dashboard-react/components/buttonStyle.js";
import { Language } from "components/Language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(styles);

const TypeToClass = {
  outlinePrimary: "btnNewOutlinePrimary",
  primary: "btnNewPrimary",
};

export function ButtonNew(props) {
  const { langKey, onClick, className, variant = "contained", ctype = "primary", children, ..._props } = props;

  return (
    <>
      <Button
        onClick={onClick}
        variant={variant}
        className={`btnNew ${TypeToClass[ctype]} ${className || ""}`}
        {..._props}
      >
        {children || (
          <>
            {langKey && (
              <>
                {/* <Language langKey="login_submit" /> */}
                <Language langKey={langKey} />
                <FontAwesomeIcon icon={faAngleRight} className="iconAngleRight" />
              </>
            )}
          </>
        )}
      </Button>
    </>
  );
}

ButtonNew.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  langKey: PropTypes.string,
  ctype: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default function RegularButton(props) {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });
  return (
    <Button {...rest} classes={muiClasses} className={btnClasses}>
      {children}
    </Button>
  );
}

RegularButton.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "rose", "white", "transparent"]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
  children: PropTypes.node,
};
