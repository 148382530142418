/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useStyles } from '../privacy/PrivacyES'

export const TermsES = () => {
  const classes = useStyles();

  return (
    <div className={classes.stacticHTML}>
      <h2 className={classes.title}>Aviso Legal</h2>

      <p className="titleBoldPrimary bold">1. Identificación</p>

      <p>El presente aviso legal regula el uso del sitio web <a href="http://evaluacion.xcelence.es">evaluacion.xcelence.es</a> (en adelante, el «<b>Sitio Web</b>«) que pone a su disposición FUNDACIÓN BERTELSMANN con domicilio en Calle O’Donnell, nº 10, 28009 Madrid (España), CIF G-07747686, teléfono +34 93 268 73 73, debidamente inscrita en el Registro de Fundaciones del Ministerio de Cultura bajo el número 268.</p>

      <p className="titleBoldPrimary bold">2. Objeto</p>

      <p>El presente Aviso Legal recoge las condiciones generales que regulan el acceso, navegación y uso del Sitio Web, así como de los contenidos que lo integran, sin perjuicio de que FUNDACIÓN BERTELSMANN pueda establecer condiciones adicionales que regulen la utilización y/o prestación de los servicios que, en su caso, puedan ser ofrecidos a través del Sitio Web. El objeto del Sitio Web es ofrecer un modelo de referencia en orientación a los centros educativos, definiendo las claves que los centros educativos deben cumplir para ofrecer un sistema de orientación de calidad a su alumnado, una herramienta de autoevaluación online que facilita a los centros educativos un diagnóstico y una comparación de su desempeño actual en la orientación académico-profesional frente a otros centros a nivel nacional. El Sitio Web proporciona, además, acceso a recomendaciones y cursos de orientación de calidad para la puesta en marcha de acciones y actividades.</p>

      <p className="titleBoldPrimary bold">3. Acceso y uso del Sitio Web</p>

      <p>La navegación por el Sitio Web de FUNDACIÓN BERTELSMANN, el uso de los contenidos ofrecidos en el mismo, así como la cumplimentación de formularios, y, en general, cualquier acto de naturaleza similar a los anteriores, le atribuye la condición de usuario del mismo e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal, que pueden sufrir modificaciones. En consecuencia, todo usuario debe leer atentamente y conocer el contenido del presente Aviso Legal. </p>

      <p>El usuario se obliga a hacer un uso correcto del Sitio Web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal. El usuario responderá frente a FUNDACIÓN BERTELSMANN o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación. </p>

      <p>Adicionalmente, se pone a disposición el presente Aviso Legal con el fin de dar cumplimiento a las obligaciones dispuestas en la legislación vigente relativas a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, así como informar a todos los usuarios del Sitio Web sobre cuáles son las condiciones de uso del mismo. También puede consultar nuestra Política de Privacidad. </p>

      <p>FUNDACIÓN BERTELSMANN condiciona la utilización de la herramienta de evaluación Xcelence+ que se aloja en este Sitio Web a la previa cumplimentación del correspondiente formulario y registro en el Sitio Web. </p>

      <p>Como consecuencia del registro, al usuario se le puede proporcionar una contraseña de la que será responsable, comprometiéndose a realizar un uso diligente y confidencial de la misma. El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a FUNDACIÓN BERTELSMANN y será el único responsable de las manifestaciones falsas o inexactas que realice. En virtud de lo anterior, es obligación del usuario informar a la FUNDACIÓN BERTELSMANN, sobre cualquier hecho que haga posible el uso indebido de los identificadores y/o contraseñas, tales como el robo, extravío, o el acceso no autorizado a los mismos por parte de terceros, con el fin de proceder a su inmediata cancelación. FUNDACIÓN BERTELSMANN quedará eximida de cualquier responsabilidad que pudiera derivarse del uso indebido de los identificadores o contraseñas por parte de terceros no autorizados, hasta que tales hechos se hayan comunicado debidamente a FUNDACIÓN BERTELSMANN. </p>

      <p>Asimismo, el usuario declara que es mayor de 14 años de edad. En caso de ser menor de 14 años de edad, el usuario deberá precisar de la autorización de sus padres o tutores antes de acceder al Sitio Web. </p>

      <p className="titleBoldPrimary bold">3.1. Registro </p>
      <p>Las funcionalidades del Sitio Web requieren el previo registro del usuario, para lo cual éste deberá dar de alta el centro educativo para el que trabaja a través del correspondiente código de centro. </p>
      <p> El usuario al registrarse, acceder o usar el servicio, confirma y garantiza que la persona física que actúa en su nombre es mayor de edad, que tiene plena capacidad legal y ostenta el debido título acreditativo de su representación. </p>
      <p>La Fundación Bertelsmann se reserva el derecho a comprobar la veracidad del título acreditativo de la persona que actúa en nombre del usuario. </p>
      <p> El registro ofrece a los usuarios la posibilidad de compartir información de las entidades a las que representan. En este sentido, el usuario declara ser el titular de los derechos –incluidos todos los derechos de propiedad intelectual- sobre cualquier información o materiales facilitados o, en su caso, garantiza que dispone de los derechos y autorizaciones necesarias del autor o propietario de los mismos, para su utilización por parte de la FUNDACIÓN BERTELSMANN o el resto de los usuarios. </p>

      <p className="titleBoldPrimary bold">4. Obligaciones del usuario</p>

      <p>Con carácter general, el usuario se obliga al cumplimiento de todas las obligaciones especificadas en el Aviso Legal vigentes en cada momento y a obrar siempre conforme a la ley, buenas costumbres y a las exigencias de la buena fe, empleando la diligencia adecuada a la naturaleza del servicio del que disfruta, absteniéndose de utilizar el Sitio Web de forma que pueda impedir, dañar o deteriorar el normal funcionamiento del mismo, los bienes o derechos de FUNDACIÓN BERTELSMANN, del resto de usuarios o en general de cualquier tercero.</p>
      <p> El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios de FUNDACIÓN BERTELSMANN y a no emplearlos para, entre otros: </p>
      <ol style={{listStyle: 'decimal'}}>
        <li>Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de apología del terrorismo o, en general, contrarios a la ley y/o al orden público. </li>
        <li>Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de FUNDACIÓN BERTELSMANN o de terceras personas; así como obstaculizar el acceso de otros usuarios al Sitio Web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales FUNDACIÓN BERTELSMANN presta sus servicios. </li>
        <li>Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de FUNDACIÓN BERTELSMANN o de terceros y, en su caso, extraer información. </li>
        <li>Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de FUNDACIÓN BERTELSMANN o de terceros. </li>
        <li>Suplantar la identidad de otro usuario, de las administraciones públicas o de un tercero.</li>
        <li>Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los contenidos, a menos que cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido. </li>
        <li>Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento. </li>
      </ol>

      <p>Todos los contenidos del Sitio Web, como textos, fotografías, gráficos, imágenes, videos, iconos, tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a FUNDACIÓN BERTELSMANN, sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso del Sitio Web. </p>

      <p>En definitiva, los usuarios que accedan a este Sitio Web pueden visualizar los contenidos y efectuar, en su caso, copias privadas autorizadas siempre que los elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen en servidores conectados a redes, ni sean objeto de ningún tipo de explotación. </p>

      <p>En ningún caso se entenderá que se concede licencia alguna o se efectúa renuncia, transmisión, cesión total o parcial, concesión ni expectativa de derecho y, en especial, de alteración, explotación, reproducción, distribución o comunicación pública sobre dichos contenidos sin la previa autorización expresa de FUNDACIÓN BERTELSMANN o de los titulares correspondientes. En consecuencia, no está permitido suprimir, eludir o manipular el aviso de derechos de autor («copyright») y cualesquiera otros datos de identificación de los derechos de FUNDACIÓN BERTELSMANN o de sus titulares, incorporados a los contenidos, así como los dispositivos técnicos de protección, las huellas digitales o cualesquiera mecanismos de información y/o de identificación que pudieren contenerse en los mismos. </p>

      <p>Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el Sitio Web son propiedad de FUNDACIÓN BERTELSMANN, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos. En ningún caso, salvo manifestación expresa en contrario, el acceso o uso del Sitio Web y/o de sus contenidos, confiere al usuario derecho alguno sobre las marcas, logotipos y/o signos distintivos en él incorporados.</p>

      <p>La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación quedan prohibidos. El uso, explotación y/o utilización no autorizada de los contenidos, así como cualquier acto de naturaleza similar del que se derive una lesión de los derechos de propiedad intelectual o industrial, dará lugar a las responsabilidades legalmente establecidas. </p>

      <p className="titleBoldPrimary bold">5. Enlaces</p>

      <p>El acceso al Sitio Web y servicios puede incluir dispositivos técnicos de enlace, directorios e incluso instrumentos de búsqueda que permiten a los usuarios acceder a sitios web de Internet (en adelante, «Sitios enlazados») de cuyo contenido FUNDACIÓN BERTELSMANN no responde. Asimismo, FUNDACIÓN BERTELSMANN no garantiza ni se responsabiliza del funcionamiento o accesibilidad de los Sitios enlazados. </p>
      <p>La presencia de estos Sitios enlazados en el Sitio Web tiene una finalidad informativa, no constituye en ningún caso una invitación a la contratación de productos y/o servicios que se ofrezcan o puedan ofrecer en los Sitios enlazados ni implica la existencia de vínculo o relación mercantil o de dependencia con la entidad titular de los Sitios enlazados.</p>

      <p>Si el usuario considera que existe un Sitio enlazado con contenidos ilícitos o inadecuados podrá comunicárselo a FUNDACIÓN BERTELSMANN a la dirección de correo electrónico a <a href="mailto:lopd@fundacionbertelsmann.org">lopd@fundacionbertelsmann.org</a>, ndicando: (a) nombre, número de teléfono y dirección de correo electrónico; (b) una descripción de los hechos que revelen el carácter ilícito o inadecuado del Sitio enlazado; y (c) una declaración expresa de que la información contenida en la comunicación es exacta.</p>

      <p>En ningún caso esta comunicación conlleva la obligación de retirar el correspondiente enlace, ni supone, según lo dispuesto en la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, el conocimiento efectivo de las actividades y/o contenidos indicados por el comunicante.</p>

      <p className="titleBoldPrimary bold">6. Modificaciones</p>

      <p>FUNDACIÓN BERTELSMANN se reserva el derecho de modificar, unilateralmente, en cualquier momento y sin previo aviso las presentes condiciones. En estos casos se procederá a su publicación y aviso con la máxima antelación posible. </p>

      <p>De igual modo, se reserva el derecho a modificar, unilateralmente, en cualquier momento y sin previo aviso, la presentación y configuración del Sitio Web. Por lo tanto, la vigencia temporal de este Aviso Legal, coincide con el tiempo de su exposición, hasta que sea modificado total o parcialmente, momento en el cual pasarán a tener vigencia los textos actualizados. </p>

      <p className="titleBoldPrimary bold">7. Uso de información y/o contenidos del Sitio Web.</p>

      <p>El usuario es consciente y acepta voluntariamente que el uso de la información publicada y/o suministrada con relación a los servicios del Sitio Web tiene lugar bajo su única y exclusiva responsabilidad; por lo tanto, salvo que la ley imponga expresamente lo contrario, y exclusivamente en la medida en que lo imponga, FUNDACIÓN BERTELSMANN no garantiza ni asume responsabilidad alguna respecto del acceso y uso de la información publicada y/o suministrada con relación a los servicios del Sitio Web. FUNDACIÓN BERTELSMANN no responderá de ninguna consecuencia, daño o perjuicio que pudieran derivarse de dicho acceso o uso de la información. </p>

      <p>FUNDACIÓN BERTELSMANN no se responsabiliza del uso que cada usuario dé a los materiales puestos a disposición en este Sitio Web ni de las actuaciones que realice en base a los mismos. </p>

      <p className="titleBoldPrimary bold">8. Exclusión de garantías y de responsabilidad</p>

      <p>El contenido del presente Sitio Web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.</p>

      <p> FUNDACIÓN BERTELSMANN excluye, hasta donde lo permita el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de: </p>

      <ol style={{listStyle: 'decimal'}}>
        <li>La falta de disponibilidad o accesibilidad del Sitio Web o continuidad técnica del mismo, ni de la existencia de interrupciones o errores en el acceso al Sitio Web, así como problemas técnicos o fallos que se produzcan durante la conexión a Internet;</li>
        <li>La ausencia de errores en los contenidos; </li>
        <li>La ausencia de virus y demás componentes dañinos en el Sitio Web o en el servidor que lo suministra; </li>
        <li>La invulnerabilidad del Sitio Web y/o la inexpugnabilidad de las medidas de seguridad que se adopten en el mismo; </li>
        <li>La fiabilidad y/o adecuación, para un propósito o fin particular, del Sitio Web, de los productos o servicios incorporados en el mismo, y de la información publicada y/o suministrada con relación a dichos productos o servicios; </li>
        <li>Los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que FUNDACIÓN BERTELSMANN establece en el Sitio Web o a través de la vulneración de los sistemas de seguridad del mismo; </li>
        <li>Cualesquiera otros daños que pudieran ser causados por motivos inherentes al no funcionamiento o al funcionamiento defectuoso del Sitio Web o de los Sitios enlazados. </li>
      </ol>

      <p>No obstante, FUNDACIÓN BERTELSMANN declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la tecnología, para garantizar el funcionamiento del Sitio Web y evitar la existencia y/o transmisión de virus y demás componentes dañinos a los usuarios. </p>

      <p>En caso de que a través del Sitio Web se ofrezcan información y/o contenidos de terceras personas o entidades, FUNDACIÓN BERTELSMANN será considerada como tercera parte, no asumiendo ninguna responsabilidad en relación con dicha información y contenidos, en especial en relación con los daños y perjuicios que puedan producirse por: </p>

      <ol style={{listStyle: 'decimal'}}>
        <li>Ausencia o deficiencias en la información facilitada a los usuarios o en su veracidad, exactitud y suficiencia; </li>
        <li>Incumplimiento o cumplimiento defectuoso o impuntual de los contratos o relaciones precontractuales; </li>
        <li>Incumplimiento de las obligaciones que incumben a los prestadores de servicios de la sociedad de la información; </li>
        <li>Infracción de los derechos de los consumidores y usuarios; </li>
        <li>Infracción de los derechos de propiedad intelectual e industrial; realización de actos de competencia desleal o de publicidad ilícita; </li>
        <li>Infracción del derecho de protección de datos; del secreto profesional y de los derechos al honor, a la intimidad personal y familiar y a la imagen de las personas; </li>
        <li>En general, el incumplimiento de cualesquiera leyes, costumbres o códigos de conducta que resulten de aplicación y; </li>
        <li>Cualquier decisión tomada en dependencia de la información suministrada a través del Sitio Web. </li>
      </ol>

      <p>Asimismo, FUNDACIÓN BERTELSMANN quedará exonerada de cualquier responsabilidad respecto a la información que se halle fuera de este Sitio Web. La función de los links que aparecen en esta web es exclusivamente la de informar al usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece este Sitio Web. FUNDACIÓN BERTELSMANN no garantiza ni se responsabiliza del funcionamiento o accesibilidad de los Sitios enlazados; ni sugiere, invita o recomienda la visita a los mismos, por lo que tampoco será responsable del resultado obtenido. FUNDACIÓN BERTELSMANN no se responsabiliza del establecimiento de hipervínculos por parte de terceros.</p>

      <p>FUNDACIÓN BERTELSMANN no se hace responsable de las deficiencias en la prestación de servicios de su proveedor de conexión a internet, ni de las redes de comunicación, ni de los problemas resultantes del mal funcionamiento o uso de versiones no optimizadas de cualquier tipo de navegador, así como de aquellas derivadas de interrupciones prolongadas del suministro eléctrico, líneas de telecomunicaciones, conflictos sociales, huelgas, explosiones, inundaciones, actos y omisiones del Gobierno, y en general de todos los supuestos de fuerza mayor o de caso fortuito.</p>

      <p>FUNDACIÓN BERTELSMANN no responderá de los posibles errores de seguridad que se puedan producir ni de los posibles daños que se ocasionasen al sistema informático del usuario, ficheros o documentos almacenados en el mismo, cuando tengan origen en un virus proveniente del ordenador del usuario, mal funcionamiento del navegador o del uso de versiones no actualizadas del mismo, de averías telefónicas, interferencias, omisiones o desconexiones en el funcionamiento operativo del sistema electrónico motivados por causas ajenas a FUNDACIÓN BERTELSMANN.</p>

      <p className="titleBoldPrimary bold">9. Tratamiento de datos personales y Cookies</p>

      <p>Los datos personales que proporcione el usuario pueden ser tratados por FUNDACIÓN BERTELSMANN en los términos y condiciones que especificados en nuestra Política de Privacidad. Para más información sobre el tratamiento de datos por parte de FUNDACIÓN BERTELSMANN por favor consulte nuestra Política de Privacidad. Al utilizar el Sitio Web, se puede recopilar cierta información a través de cookies. Para más información, por favor consulte nuestra Política de Cookies.</p>

      <p className="titleBoldPrimary bold">10. Sitio Web Legislación aplicable y tribunales competentes</p>

      <p>El presente Aviso Legal y las relaciones establecidas entre FUNDACIÓN BERTELSMANN y el usuario, en particular el conocimiento y resolución de cualesquiera litigios, discrepancias o diferencias que pudieran surgir, se regirán y resolverán de conformidad con lo establecido en la normativa española.</p>

      <p>No obstante, para los casos en los que la normativa prevea la posibilidad a las partes de someterse a un fuero, FUNDACIÓN BERTELSMANN y el usuario, con renuncia expresa a cualquier otro que pudiera corresponderles, someterán cualesquiera controversias y/o litigios al conocimiento de los Juzgados y Tribunales de la ciudad del domicilio de FUNDACIÓN BERTELSMANN en el presente Aviso Legal.</p>

    </div>
  );
}
