import { takeEvery, call, put } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";

export default function* watcherMunicipalities() {
  yield takeEvery(actionTypes.GET_MUNICIPALITIES, getMunicipalities);
  yield takeEvery(actionTypes.GET_POSTALCODES, getPostalCodes);

  function* getPostalCodes() {
    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint = "/v1/codigos-postales";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then(response => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.SET_POSTALCODES, payload: res.data });
      }
    } catch (e) {/**/}
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getMunicipalities() {
    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint = "/v6/municipios";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then(response => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.SET_MUNICIPALITIES, payload: res.data });
      } else {
        // console.log("ERROR");
      }
    } catch (e) {
      /*  */
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
}
