/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
import React from "react";

export const CoockiesCA = () => (
  <>
    <div className="h1home marginSt">Política de Cookies</div>
    <div
      className="textPrivacity
  marginSubSt"
    >
      Aquest lloc web www.xcelence.es (d'ara endavant, el Lloc Web) utilitza
      cookies pròpies i de tercers per millorar la seva experiència en aquesta.{" "}
      <br />
      <br />
      <br />
      <strong>1. Què són les galetes? </strong>
      <br />
      <br />
      Una Cookie és un petit fitxer que s'emmagatzema a l'ordinador de l'usuari
      i ens permet reconèixer-lo. El conjunt de galetes ens ajuda a millorar la
      qualitat del nostre Lloc Web. <br />
      <br />
      Les galetes són essencials per al funcionament d'internet, aportant
      innombrables avantatges en la prestació de serveis interactius,
      facilitant-li la navegació i usabilitat del nostre Lloc Web. Tingueu en
      compte que les galetes no poden danyar el vostre equip i que, a canvi, el
      que estiguin activades ens ajuden a identificar i resoldre els errors.{" "}
      <br />
      <br />
      <strong>
        2. Quins tipus de galetes utilitza el nostre Lloc Web?
      </strong>{" "}
      <br />
      <br />
      Segons l'entitat que les gestiona:
      <ul>
        <li>
          Cookies pròpies: són les que s'envien a l'equip terminal de l'usuari
          des d'un equip o domini gestionat pel mateix editor i des del qual es
          presta el servei sol·licitat per l'usuari.
        </li>
        <li>
          Galetes de tercers: són aquelles que s'envien a l'equip terminal de
          l'usuari des d'un equip o domini que no és gestionat per l'editor,
          sinó per una altra entitat que tracta les dades obtingudes a través de
          les galetes. En el cas que les cookies siguin instal·lades des d'un
          equip o domini gestionat pel mateix editor però la informació que es
          reculli mitjançant aquestes sigui gestionada per un tercer, no poden
          ser considerades com a cookies pròpies.
        </li>
      </ul>
      Segons el termini de temps que romanen actives:
      <ul>
        <li>
          Cookies de sessió: són cookies temporals que romanen a l'arxiu de
          cookies del vostre navegador fins que abandoni el Lloc Web, per la
          qual cosa cap queda registrada al disc dur de l'usuari. La informació
          obtinguda per mitjà d'aquestes galetes, serveixen per analitzar pautes
          de trànsit al Lloc Web. A la llarga, això ens permet proporcionar una
          millor experiència per millorar el contingut facilitant-ne l'ús.
        </li>
        <li>
          Cookies permanents: són emmagatzemades al disc dur i el nostre Lloc
          Web les llegeix cada vegada que vostè realitza una nova visita. Un
          Lloc Web permanent té una data d'expiració determinada. La galeta
          deixarà de funcionar després d'aquesta data.
        </li>
      </ul>
      Segons el propòsit:
      <ul>
        <li>
          Galetes tècniques: Són aquelles necessàries per a la navegació i el
          bon funcionament del nostre Lloc Web. Permeten, per exemple, controlar
          el trànsit i la comunicació de dades, accedir a parts daccés
          restringit, utilitzar elements de seguretat, emmagatzemar continguts
          per poder difondre vídeos o compartir continguts a través de xarxes
          socials.
        </li>
        <li>
          Cookies d'anàlisi: Són aquelles que ben tractades per nosaltres o per
          tercers, ens permeten quantificar el nombre d'usuaris i realitzar el
          mesurament i l'anàlisi estadística de la utilització que fan els
          usuaris del nostre Lloc Web. Per això analitzem la navegació al nostre
          Lloc Web per tal de millorar l'oferta de serveis que oferim.
        </li>
      </ul>
      <br />
      <br />
      <strong>
        3. Relació i descripció de les cookies pròpies que utilitzem al Lloc Web
      </strong>
      <br />
      <br /> Nom de la galeta: xcauth <br />
      Descripció: Aquesta galeta informa si l'usuari està autenticat o no <br />
      Servidor des d'on s'envia: www.xcelence.es
      <br />
      Pròpia o de tercers: Pròpia <br />
      Finalitat: Necessària <br />
      Data de caducitat: 90 dies <br />
      Exclosa (del deure de la informació i consentiment)
      <br />
      <br />
      Nom de la cookie: xctoken <br />
      Descripció: Cookie que emmagatzema el token d'autenticació de l'usuari
      connectat <br />
      Servidor des del qual s'envia: www.xcelence.es <br />
      Pròpia o de tercers: Pròpia <br />
      Finalitat: Necessària <br />
      Data de caducitat: 90 dies <br />
      Exclosa (del deure de la informació i consentiment)
      <br />
      <br />
      Nom de la galeta: xcrefreshToken <br />
      Descripció: Cookie que emmagatzema el token per actualitzar l'autenticació
      de l'usuari connectat <br />
      Servidor des d'on s'envia: www.xcelence.es <br />
      Pròpia o de tercers: Pròpia <br />
      Finalitat: Necessària <br />
      Data de caducitat: 90 dies <br />
      Exclosa (del deure de la informació i consentiment) <br />
      <br />
      Nom de la cookie: xcuser <br />
      Descripció: Cookie que emmagatzema les dades de l'usuari <br />
      Servidor des d'on s'envia: www.xcelence.es <br />
      Pròpia o de tercers: Pròpia <br />
      Finalitat: Necessària <br />
      Data de caducitat: 90 dies <br />
      Exclosa (del deure de la informació i consentiment)
      <br />
      <br />
      <br />
      <br />
      <strong>
        4. Relació de tercers prestadors de serveis de cookies al Lloc Web
      </strong>{" "}
      <br />
      <br />
      Nom del proveïdor: Google Inc. <br />
      Descripció: Cookies de Google Analytics. Generen un ID d'usuari anònim,
      que és el que s'utilitza per fer recompte quantes vegades visita el lloc
      un usuari. També registra quan va ser la primera i la darrera vegada que
      va visitar la web. Així mateix, calcula quan s'ha acabat una sessió,
      l'origen de l'usuari i els keywords. (Cookies:_ga, _gat). <br />
      Finalitat: Analítiques
      <br />
      Si desitgeu més informació de l'ús de les cookies de tercers:
      https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
      *Aquesta llista s'actualitzarà amb la major celeritat possible a mesura
      que canviïn els serveis del Lloc Web oferts al mateix. No obstant això,
      ocasionalment durant aquesta actualització pot ser que la llista no
      inclogui ja una galeta, encara que sempre es referirà a galetes amb
      propòsits idèntics als registrats en aquesta llista. <br />
      <br />
      <strong>5. Garanties complementàries - Gestió de cookies</strong>
      <br />
      <br />
      Com a garantia complementària a les anteriorment descrites, el registre de
      les cookies podrà estar subjecte a la seva acceptació durant la
      instal·lació o posada al dia del navegador usat, i aquesta acceptació pot
      ser revocada en tot moment mitjançant les opcions de configuració de
      continguts i privadesa disponibles. <br />
      <br />
      Molts navegadors permeten activar un mode privat mitjançant el qual les
      cookies s'esborren sempre després de la visita. Depenent de cada navegador
      aquest mode privat, podeu tenir diferents noms. A continuació trobareu una
      llista dels navegadors més comuns i els diferents noms d'aquest "mode
      privat". <br />
      <br />
      <strong>6. Consentiment</strong> <br />
      <br />
      En accedir al nostre Lloc Web, apareix una finestra emergent relativa a
      les Cookies, descrivint breument quines galetes s'utilitzen al Lloc Web.
      En aquesta finestra podeu seleccionar “Acceptar”, atorgant el vostre
      consentiment per a l'ús de les cookies abans enunciades en les condicions
      contingudes en aquesta Política de Cookies. <br />
      <br />A partir de l'opció que preneu sobre l'ús de cookies en aquest Lloc
      Web us enviarà una galeta addicional per salvaguardar la vostra elecció.{" "}
      <br />
      <br />
      Per utilitzar aquest Lloc Web no és necessària la instal·lació de cookies.
      L'usuari pot no acceptar-les configurant el vostre navegador per
      bloquejar-les i, si escau, eliminar-les. <br />
      <br />
      <strong>7. Com permetre, bloquejar o eliminar galetes?</strong>
      <br />
      <br />
      D'acord amb el que estableix el Reial decret llei 13/2012 us informem que
      podeu permetre, bloquejar o eliminar les cookies instal·lades al vostre
      equip mitjançant la configuració de les opcions del vostre navegador
      d'internet. En cas que les bloquegeu, és possible que certs serveis que
      necessiten el seu ús no estiguin disponibles per a vostè. <br />
      <br />A continuació, us oferim enllaços on trobareu informació sobre com
      podeu activar les vostres preferències als principals navegadors: <br />
      <br />
      <a
        href="https://support.google.com/chrome/answer/95647?hl=es"
        target="_blank"
        className="maindarkLink"
      >
        Google Chrome{" "}
      </a>
      <br />
      <a
        href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectlocale=en-US&amp;redirectslug=Cookies"
        target="_blank"
        className="maindarkLink"
      >
        Mozilla Firefox{" "}
      </a>
      <br />
      <a
        href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11"
        target="_blank"
        className="maindarkLink"
      >
        Internet Explorer{" "}
      </a>
      <br />
      <a
        href="http://www.apple.com/legal/privacy/es/cookies/"
        target="_blank"
        className="maindarkLink"
      >
        Safari{" "}
      </a>
      <br />
      <a
        href="https://support.apple.com/es-es/HT201265"
        target="_blank"
        className="maindarkLink"
      >
        Safari per a IOS (iPhone, iPad){" "}
      </a>
      <br />
      <a
        href="http://www.macromedia.com/support/documentation/es/flashplayer/help/settings_manager07.html"
        target="_blank"
        className="maindarkLink"
      >
        Cookies Flash{" "}
      </a>
      <br />
      <a
        href="http://help.opera.com/Windows/12.00/es-ES/cookies.html"
        target="_blank"
        className="maindarkLink"
      >
        Opera{" "}
      </a>
      <br />
      <a
        href="https://support.google.com/chrome/answer/2392971?hl=es"
        target="_blank"
        className="maindarkLink"
      >
        Chrome per a Android
      </a>
      <br />
      <br />
      Finalment, podeu adreçar-vos al portal{" "}
      <a
        href="http://www.youronlinechoices.eu/"
        target="_blank"
        className="maindarkLink"
      >
        Your Online Choices
      </a>{" "}
      <a
        href="http://www.youronlinechoices.eu/"
        target="_blank"
        className="maindarkLink"
      ></a>{" "}
      on a més de trobar informació útil, podreu configurar, proveïdor per
      proveïdor, les vostres preferències sobre les cookies publicitàries de
      tercers. <br />
      <br />
      <strong>8. Important</strong>
      <br />
      <br />
      Si us plau, llegiu atentament la secció d'ajuda del vostre navegador per
      conèixer més sobre com activar el "mode privat". Podreu seguir visitant el
      nostre Lloc Web encara que el vostre navegador estigui en "mode privat",
      si bé, la vostra navegació pel nostre Lloc Web pot no ser òptima i algunes
      utilitats poden no funcionar correctament. <br />
      <br />
      <strong>9. Contacta amb nosaltres</strong>
      <br />
      <br /> Per a més informació sobre el tractament de dades personals,
      consulteu la nostra Política de Privadesa o contacteu-nos: <br />
      <br />
      Adreça: Travessera de Gràcia, 47-49, - 08021 Barcelona (Espanya), <br />
      <br />
      Adreça de correu electrònic: info@fundaciónbertelsmann.org
      <br />
      <br />
      Telèfon: + 34 93 268 73 73
    </div>
  </>
);
