/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import NavBarAuthNew from "views/NavBar/NavBarAuthNew.js";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";
import { centroActions } from "actions/centroActions";
import masterData from "constants/masterData";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Language, getRawMessage } from "components/Language";
import { Link } from "react-router-dom";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CardNew from "components/Card/CardNew.js";
import { ButtonNew } from "components/CustomButtons/Button";
import CheckedIcon, { CheckedIconOk } from "views/Icons/CheckedIcon";
import IconArrowDark from "components/icons/IconArrowDark";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RegisterOtros() {
  const dispatch = useDispatch();

  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [valid, setValid] = useState(false);
  const [send, setSend] = useState(false);

  const [busca, setBusca] = useState("");

  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [emailDirector, setEmailDirector] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [terminos, setTerminos] = useState(false);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openAviso, setOpenAviso] = useState(false);
  const [mailError, setMailError] = useState(false);

  const [mailErrorDirector, setMailErrorDirector] = useState(false);

  const [mailExiste, setMailExiste] = useState(false);
  const [mailDirectorExiste, setMailDirectorExiste] = useState(true);

  const [saveError, setSaveError] = useState(false);

  const [email, setEmail] = React.useState("");
  const [codigoCentro, setCodigoCentro] = useState("");
  const [codigoError, setCodigoError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const locale = useSelector((state) => state.setting.locale);
  const [language, setLanguage] = useState(locale);

  const handleClose = () => {
    setOpen(false);
    setOpenAviso(false);
  };

  const setChangeCodigoCentro = (event) => {
    setCodigoCentro(event.target.value);
  };

  const setChangeEmailDirector = (event) => {
    setEmailDirector(event.target.value);
  };

  const setChangeNombre = (event) => {
    setNombre(event.target.value);
  };
  const setChangeApellidos = (event) => {
    setApellidos(event.target.value);
  };
  const setChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const setChangeMensaje = (event) => {
    setMensaje(event.target.value);
  };

  const setChangeBusca = (event) => {
    setBusca(event.target.value);
  };

  var centrosSearchData = useSelector((state) => state.centroReducer.centrosSearchData);
  const setChangeTerminos = (event) => {
    setTerminos(event.target.checked);
  };
  var emailvalid = useSelector((state) => state.authReducer.emailvalid);
  var emailerror = useSelector((state) => state.authReducer.emailerror);

  var altaOtrosSuccess = useSelector((state) => state.authReducer.altaOtrosSuccess);
  var altaOtrosError = useSelector((state) => state.authReducer.altaOtrosError);

  document.body.style.overflow = "auto";
  const validEmail = function(mEmail) {
    var isValid = true;
    var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!mEmail.match(EMAIL_REGEX)) {
      isValid = false;
    }

    var domain = mEmail.replace(/.*@/, "").toLowerCase();
    if (!(domain !== "gmail.com" && domain !== "hotmail.com" && domain !== "yahoo.com" && domain !== "outlook.com")) {
      isValid = false;
    }
    if (mEmail === "") {
      isValid = false;
    }

    return isValid;
  };

  const saveForm = () => {
    setSubmitted(true);

    if (validEmail(email)) {
      setMailError(false);
    } else {
      setMailError(true);
    }

    if (validEmail(emailDirector)) {
      setMailErrorDirector(false);
    } else {
      setMailErrorDirector(true);
    }

    if (
      nombre !== "" &&
      nombre.length > 1 &&
      nombre.length < 40 &&
      mensaje !== "" &&
      mensaje.length > 2 &&
      apellidos !== "" &&
      apellidos.length > 1 &&
      apellidos.length < 60 &&
      codigoCentro !== "" &&
      codigoCentro.length > 2 &&
      codigoCentro.length < 15
    ) {
      setCodigoError(false);
      dispatch(userActions.findEmail(email));
      setLoading(true);
      setValid(true);
    } else {
      if (codigoCentro === "" || codigoCentro.length < 2 || codigoCentro.length > 15) {
        setCodigoError(true);
      }

      setValid(false);
    }
  };

  useEffect(() => {
    if (emailerror) {
      setMailError(true);
      setLoading(false);
    }

    if (emailvalid === 0) {
      setSend(true);
      setMailError(false);
      setMailExiste(false);

      if (!sending) {
        dispatch(
          userActions.registerOtros({
            nombre,
            apellidos,
            email,
            emailDirector,
            codigoCentro,
            language,
            perfil: masterData.PROFILE_OTROS,
            cargo: 0,
            mensajealta: mensaje,
          })
        );
        setSending(true);
      }
    }

    if (emailvalid === 1) {
      setLoading(false);
      dispatch(userActions.clearAlta());
      setMailError(false);
      setMailExiste(true);
      setValid(false);

      setSending(false);
      setSend(false);
    } else {
      setMailError(false);
      setMailExiste(false);
    }

    if (altaOtrosSuccess === 0) {
      setSending(false);
      setSend(false);
      setLoading(false);
      dispatch(userActions.endRegister());
      setSaveError(false);
      setCodigoError(true);
    }
    if (altaOtrosSuccess && altaOtrosSuccess.code === 200) {
      setSending(false);
      setSend(false);
      setLoading(false);
      setSaveError(false);
      dispatch(userActions.endRegister());
      history.push("/user/register/okotros");
    }

    if (altaOtrosError) {
      setLoading(false);
      setSend(false);
      setSending(false);
      dispatch(userActions.endRegister());
      // ha habido un error
      setSaveError(true);
    }
  }, [valid, emailvalid, emailerror, altaOtrosSuccess, altaOtrosError]);

  const buscaCodigo = () => {
    setOpen(true);
  };

  const doSearch = () => {
    if (busca !== "") {
      dispatch(centroActions.searchCentroName(busca));
    }
  };
  const keypress = (event) => {
    if (event.charCode === 13 || event.keyCode === 13) {
      doSearch();
    }
  };

  const copyText = (e) => {
    setOpenAviso(true);
    setOpen(false);
    setCodigoCentro(e);
  };

  return (
    <div className="fullPageNew">
      <NavBarAuthNew />
      <div className="defaultFull defaultFullNew">
        <Snackbar
          open={openAviso}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            <Language langKey="code_copied" />
          </Alert>
        </Snackbar>
        <Modal show={open} onHide={handleClose} style={{ width: "700px !important" }}>
          <Modal.Header closeButton className="headerModal">
            <Language langKey="search_center" />
          </Modal.Header>
          <Modal.Body>
            <Box style={{ textAlign: "justify" }}>
              <TextField
                onKeyPress={(e) => keypress(e)}
                onChange={(e) => setChangeBusca(e)}
                className="inputTransform"
                defaultValue={busca}
                id="busca"
                placeholder={getRawMessage("signup.search")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ cursor: "pointer" }} onClick={(e) => doSearch()}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              {centrosSearchData && (
                <div className="containerCentros">
                  <div className="center-col">
                    {centrosSearchData.map((item, i) => (
                      <div key={i} className="itemCentro">
                        <div className="centroNameList">{item.nombre}</div>
                        <div className="centroAddressList">{item.direccion}</div>
                        <div className="centroAddressList">
                          {item.cp} {item.municipio}
                        </div>
                        <div className="centroCodigoList">{item.codigo}</div>
                        <div className="derecha">
                          <CopyToClipboard
                            className="maindarkLink .arrow-next"
                            text={item.codigo}
                            onCopy={(e) => copyText(e)}
                          >
                            <span className="maindarkLink .arrow-next">
                              <Language langKey="signup.copy_code" />
                            </span>
                          </CopyToClipboard>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <ButtonNew className="btnCenter btnNewPrimary" onClick={() => doSearch()} langKey="search" />
          </Modal.Footer>
        </Modal>

        <CardNew>
          <Grid container spacing={0} justifyContent="center">
            <Grid item xs={12}>
              <div className="h1homeNew h1homeNewLimitWidth">
                <Language langKey="signup.teacher" />
              </div>
              <div className="subh1home subh1homeNew">
                <Language langKey="signup.i_am_teacher" />
              </div>
              <div className="txtHomeNew">
                <Language parse langKey="signup.teacher_caption" />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.name" /> *
              </div>
              <TextField
                onChange={(e) => setChangeNombre(e)}
                id="nombre"
                name="nombre"
                error={submitted && !nombre}
                className="inputTransform"
                variant="outlined"
                required
                placeholder={getRawMessage("signup.name_caption")}
              />
              {submitted && !nombre && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_name" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.last_name" /> *
              </div>
              <TextField
                onChange={(e) => setChangeApellidos(e)}
                className="inputTransform"
                variant="outlined"
                error={submitted && !apellidos}
                required
                id="apellidos"
                placeholder={getRawMessage("signup.last_name")}
              />
              {submitted && !apellidos && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_last" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.email" /> *
              </div>
              <TextField
                onChange={(e) => setChangeEmail(e)}
                className="inputTransform"
                variant="outlined"
                error={submitted && mailError}
                required
                id="mail"
                placeholder={getRawMessage("signup.email_caption")}
              />

              {submitted && mailError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.invalid_email" />
                </div>
              )}
              {submitted && mailExiste && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.exist_email" />
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.dir_email" /> *
              </div>
              <TextField
                onChange={(e) => setChangeEmailDirector(e)}
                className="inputTransform"
                variant="outlined"
                error={submitted && mailErrorDirector}
                required
                id="emailDirector"
                placeholder={getRawMessage("signup.dir_email_caption")}
              />
              {submitted && mailErrorDirector && (
                <div className="errorLayer">
                  {!mailDirectorExiste ? (
                    <span>
                      <i className="fa fa-exclamation-triangle marginerror"></i>
                      <Language langKey="errors.no_director" />
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-exclamation-triangle marginerror"></i>
                      <Language langKey="errors.center_email" />
                    </span>
                  )}
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="lang.label" />
              </div>
              <FormControl variant="outlined" className="mt-3 w-100">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  className="inputTransform"
                  IconComponent={IconArrowDark}
                  id="demo-simple-select-outlined"
                  value={language}
                  onChange={(value) => setLanguage(value.target.value)}
                >
                  <MenuItem value="ES">
                    <Language langKey="lang.es" />
                  </MenuItem>
                  <MenuItem value="CA">
                    <Language langKey="lang.ca" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.center_code" /> *
              </div>
              <TextField
                onChange={(e) => setChangeCodigoCentro(e)}
                className="inputTransform"
                variant="outlined"
                error={submitted && (codigoError || !codigoCentro)}
                defaultValue={codigoCentro}
                value={codigoCentro}
                required
                id="emailDirector"
                placeholder={getRawMessage("signup.center_code_caption")}
              />
              {submitted && (!codigoCentro || codigoCentro.length < 2) && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.empty_code" />
                </div>
              )}
              {submitted && (codigoError || codigoCentro.length > 15) && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.code" />
                </div>
              )}

              <div className="h5New spaceBottom1">
                <div onClick={buscaCodigo}>
                  <Language parse langKey="signup.search_code_link" />
                </div>

                <Language parse langKey="signup.no_center" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="formTextNew">
                <Language langKey="signup.message" /> *
              </div>
              <TextField
                multiline
                minRows={4}
                onChange={(e) => setChangeMensaje(e)}
                className="inputTransform"
                aria-label="minimum height"
                variant="outlined"
                value={mensaje}
                required
                id="mensaje"
                placeholder={getRawMessage("signup.message_caption")}
              />
              {submitted && !mensaje && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className="labelForm labelFormNew"
                error="true"
                control={
                  <Checkbox
                    checked={terminos}
                    onChange={setChangeTerminos}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    icon={<CheckedIcon />}
                    checkedIcon={<CheckedIconOk />}
                  />
                }
                label={<Language parse langKey="signup.terms" />}
              />
              {submitted && !terminos && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.terms" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <ButtonNew
                onClick={() => saveForm()}
                disabled={!!loading}
                className={`${loading ? "btnDisabledNew" : ""}`}
                langKey="send"
              />
              <br />
              <Link to={{ pathname: "/politica-privacidad/" }} className="maindarkLink">
                <Language langKey="privicy_policies" />
              </Link>

              <p className="obtext obtextNew">
                * <Language langKey="signup.confidential" />
              </p>
              {saveError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.internal" />
                </div>
              )}
              {loading && <CircularProgress size={24} className="centerLoading" />}

              {saveError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.internal" />
                </div>
              )}
            </Grid>
          </Grid>
        </CardNew>
      </div>
    </div>
  );
}

export default RegisterOtros;
