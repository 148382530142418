import React, { useState } from "react";
import logoXcelence from "assets/img/logoXcelence.png";
import logoXcelenceNew from "assets/img/xcelence.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguge } from "actions/settings.action";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { AccountCircleOutlined, Language as LanguageIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { Language } from "components/Language";
import styled from "styled-components";
import { StyledNavbar, StyledNavbarWrap } from "./NavBarAuthNew";

const NavBarNew = () => {
  const dispatch = useDispatch();
  const [dropLang, setDropLang] = useState(false);
  const locale = useSelector((state) => state.setting.locale);

  const handleChangeLocale = (lang = "ES") => {
    localStorage.setItem("lang", lang);

    const reg = new RegExp("^/(es|ca)/");
    const url = new URL(window.location.href);
    url.pathname = url.pathname.replace(reg, `/${lang.toLowerCase()}/`);

    window.history.pushState({}, "", url);

    dispatch(changeLanguge(lang));
  };

  const lang = locale.toLowerCase();

  return (
    <StyledNavbarWrap expand="lg">
      <StyledNavbar expand="lg" className="menuPrincipalNew">
        <div className="bgBlurNavLeft"></div>
        <div className="bgBlurNavRight"></div>
        <Link className="py-2 navbar-brand" to={{ pathname: "/" }}>
          <img src={logoXcelenceNew} />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbarToggleNew" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav className="justify-content-end">
            <Link className="btnRegisterMenu" to={{ pathname: `/${lang}/user/login` }}>
              <AccountCircleOutlined fontSize="small" /> <Language langKey="self_evaluation" />
            </Link>
            <NavDropdown
              show={dropLang}
              onMouseOver={() => setDropLang(true)}
              onMouseLeave={() => setDropLang(false)}
              className="menu-item-navbar lang-dropdown  lang-dropdown-new"
              title={
                <>
                  <LanguageIcon fontSize="small" /> {locale}
                </>
              }
            >
              <NavDropdown.Item onClick={handleChangeLocale.bind(null, "ES")} className="submenu-item-navbar">
                <Language langKey="lang.es" />
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleChangeLocale.bind(null, "CA")} className="submenu-item-navbar">
                <Language langKey="lang.ca" />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </StyledNavbar>
    </StyledNavbarWrap>
  );
};

export default NavBarNew;
